<template>
    <div
        class="textblock flex s-down:flex-col align-center mb-xxl"
        :class="{
            'textblock--odd m-up:flex-row': (index + 1) % 2,
            'm-up:flex-row-reverse': index % 2
        }"
    >
        <div class="textblock__text flex-grow flex-shrink">
            <IntroText
                class="text-center"
                :title="data.name"
                :text="data.text_en"
                :actions="data.button_title_en && data.button_link ? [{
                    href: data.button_link,
                    class: 'bg-accent-light color-primary',
                    title: data.button_title_en
                    }] : []
                "
            />
        </div>

        <div
            v-if="data.video || data.image"
            class="textblock__img rounded shadow-m overflow-hidden relative s-down:mt-xl"
            :class="[{ 'textblock__img--video borderhover': data.video }]"
            @click="onImageClick"
        >
            <img
                v-lazy="data.video ? videourl(data.video) : cdnurl(data.image)"
                :alt="data.image_caption"
                class="block w-12"
                :class="{ 'cursor-pointer': data.video }"
            />
            <PlayIcon v-if="data.video" :size="36" class="color-white" />
        </div>
    </div>
</template>

<script>
import PlayIcon from 'vue-material-design-icons/PlayCircle.vue';
export default {
    name: 'Textblock',
    components: { PlayIcon },
    props: {
        data: Object,
        index: Number,
    },
    methods: {
        onImageClick() {
            if (this.data.video) {
                this.$store.commit('setOverlayVideo', { type: 'youtube', id: this.data.video });
            }
        },
    },
};
</script>

<style>
    .textblock__img {
        --textblock-image-width: 100%;
        --textblock-image-margin: 0;
        flex: 0 0 var(--textblock-image-width);
    }
        .textblock__img--video > div {
            overflow: hidden;
        }
            .textblock__img--video img {
                margin-top: -10%;
                margin-bottom: -10%;
            }

    .textblock__img button,
    .textblock__img .button,
    .textblock__img .material-design-icon {
        position: absolute;
        left: var(--size);
        bottom: var(--size);
    }

    @media (min-width: 48em) {
        .textblock__img {
            --textblock-image-width: 50%;
            margin-right: var(--size-xxl);
            margin-left: 0;
        }
        .textblock.textblock--odd .textblock__img {
            margin-right: 0;
            margin-left: var(--size-xxl);
        }
        .textblock.textblock--odd .textblock__img button,
        .textblock.textblock--odd .textblock__img .button {
            left: calc(var(--size) + var(--size-xxl));
        }
        .textblock h2 span { display: block; }
    }
    @media (min-width: 62em) {
        .textblock {
            width: 110%;
            margin-left: -10%;
        }
        .textblock.textblock--odd {
            margin-left: 0;
            margin-right: -10%;
        }

        .textblock__img {
            --textblock-image-width: 54.5%;
        }
    }
</style>
