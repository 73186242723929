<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
            <div class="textblocks c w-m overflow-hidden">
                <Textblock
                    v-for="(block, index) in textblocks"
                    :key="block.id"
                    :index="index"
                    :data="block"
                />
            </div>
        </WaveDownBG>

        <div class="c w-xl flex columns flex-wrap align-stretch justify-center my-xxl py-xxl">
            <ImageCard
                class="w-6 m-up:w-4 l-up:w-3"
                v-for="item in list"
                :key="item.id"
                :title="item.name"
                :text="item.subtitle_en"
                :image="cdnurl(item.image || item.image1)"
                :ratio="cat ? undefined : '3by2'"
                @click="cat ? openProjectItem(item) : openProjectCat(item)"
            />
        </div>
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDown.vue';
import Textblock from '@/components/ui/Textblock.vue';
import ImageCard from '@/components/ui/ImageCard.vue';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Projects',
    components: {
        WaveDownBG,
        Textblock,
        ImageCard,
    },
    props: {
        cat: Number,
    },
    data() {
        return {
            list: [],
        };
    },
    methods: {
        kebabcase,

        // TODO add map
        getData(cat = this.cat) {
            if (typeof this.cat === 'number' && typeof cat === 'number') {
                this.$store.dispatch('get', {
                    page: 'items/foundation_projects/',
                    params: {
                        category: this.cat,
                    },
                }).then(this.processData);
            } else {
                this.list = this.data.foundation_projects_categories;
            }
        },

        openProjectCat(item) {
            this.$router.push({
                name: 'ProjectsCat',
                params: {
                    cat: item.id,
                    slug: kebabcase(item.name),
                },
            });
        },

        openProjectItem(item) {
            this.$router.push({
                name: 'Project',
                params: {
                    id: item.id,
                    slug: kebabcase(item.name),
                },
            });
        },

        processData(r) {
            this.list = r;
            this.setMeta(r);
        },

        setMeta(data) {
            if (this.data) {
                let schemas = [];
                if (this.$route.meta.schema) {
                    if (typeof this.$route.meta.schema === 'function') {
                        schemas = this.$route.meta.schema(Array.isArray(data) ? data : [], this);
                    } else {
                        schemas = this.$route.meta.schema;
                    }
                }

                this.$store.commit('setMeta', {
                    data: {
                        title: this.data.projects_meta_title_nl,
                        description: this.data.projects_meta_description_nl,
                        keywords: (Array.isArray(this.data.projects_meta_keywords_nl) ? this.data.projects_meta_keywords_nl : []).map((it) => it.name).join(', '),
                    },
                    schemas,
                });
            }
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        textblocks() {
            if (!this.cat && this.data && this.data.foundation_textblocks) {
                return this.data.foundation_textblocks.filter((it) => it.section === 'Projects');
            }
            if (this.cat) {
                return [{
                    name: this.selectedCategory.name,
                    text_en: this.selectedCategory.subtitle_en,
                }];
            }
            return [];
        },

        selectedCategory() {
            if (this.cat && this.data && this.data.foundation_projects_categories) {
                return this.data.foundation_projects_categories.find((it) => it.id === this.cat);
            }
            return false;
        },
    },

    watch: {
        cat: {
            handler: 'getData',
            immediate: true,
        },

        data: {
            handler: 'getData',
            immediate: true,
        },
    },
};
</script>
