<template functional>
    <div
        class="imagecard transition"
        :class="data.staticClass"
        @click="listeners.click"
    >
        <div
            class="rounded bg-cover bg-accent-light overflow-hidden cursor-pointer borderhover"
            :class="'ratio-' + (props.ratio ? props.ratio : '2by3')"
            v-lazy:background-image="props.image"
        >
            <div class="flex flex-col">
                <div class="imagecard__top px-l py-r flex-grow color-accent weight-b font-title z-5">
                    {{ props.cat }}
                </div>
                <div class="imagecard__bottom px-l py-r color-white relative z-5">
                    <h4 class="leading-0 mb-0 color-white">{{ props.title }}</h4>
                    <div v-html="parent.$options.filters.nl2br(props.text)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageCard',
};
</script>

<style>
.imagecard > div > .flex::after,
.imagecard > div > .flex::before {
    position: absolute;
    z-index: 1;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
}
.imagecard > div > .flex::after {
    height: 12%;
    top: 0;
    bottom: auto;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
}
</style>
