var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"textblock flex s-down:flex-col align-center mb-xxl",class:{
        'textblock--odd m-up:flex-row': (_vm.index + 1) % 2,
        'm-up:flex-row-reverse': _vm.index % 2
    }},[_c('div',{staticClass:"textblock__text flex-grow flex-shrink"},[_c('IntroText',{staticClass:"text-center",attrs:{"title":_vm.data.name,"text":_vm.data.text_en,"actions":_vm.data.button_title_en && _vm.data.button_link ? [{
                href: _vm.data.button_link,
                class: 'bg-accent-light color-primary',
                title: _vm.data.button_title_en
                }] : []}})],1),(_vm.data.video || _vm.data.image)?_c('div',{staticClass:"textblock__img rounded shadow-m overflow-hidden relative s-down:mt-xl",class:[{ 'textblock__img--video borderhover': _vm.data.video }],on:{"click":_vm.onImageClick}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.data.video ? _vm.videourl(_vm.data.video) : _vm.cdnurl(_vm.data.image)),expression:"data.video ? videourl(data.video) : cdnurl(data.image)"}],staticClass:"block w-12",class:{ 'cursor-pointer': _vm.data.video },attrs:{"alt":_vm.data.image_caption}}),(_vm.data.video)?_c('PlayIcon',{staticClass:"color-white",attrs:{"size":36}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }